@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: #fff;
}

body {
  background-color: #080202;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.info {
  display: flex;
  height: 250px;
  padding-top: 150px;
  justify-content: center;
  align-items: center;
}

.title-main {
  display: flex;
  height: 200px;
  padding-top: 200px;
  justify-content: center;
  align-items: center;
}

.title-main .title {
  text-align: center;
}

.cmd {
  display: flex;
  height: auto;
  margin-bottom: 100px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

.comando {
  background-color: #161617;
  border-radius: 10px;
  width: 600px;
  padding: 20px;
}

.comando h2 {
  margin: 10px;
}

@media (max-width: 720px) {

  .comando {
    margin:  50px;
  }
}
