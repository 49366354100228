@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: #fff;
}

body {
  background-color: #080202;
  font-family: "Poppins", sans-serif;
  color: #fff;
}


.title-main {
  display: flex;
  height: 250px;
  padding-top: 150px;
  justify-content: center;
  align-items: center;
}

.title-main .title {
  text-align: center;
}

.premium {
  display: flex;
  height: auto;
  margin-bottom: 100px;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.content-premium {
  display: flex;

}

.content-premium .cartel {
  background-color: #1c1c1c;
  padding: 50px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px;
  width: 400px;
}

.texto .top {
  margin-bottom: 20px;
}

.content-premium .cartel img {
  height: 200px;
  padding: 20px;
}

.btn1{
  background-color: #5c3c0b;
  padding: 10px;
  border-radius: 10px;
}

.btn2 {
  background-color: #636363;
  padding: 10px;
  border-radius: 10px;
}

.btn3 {
  background-color: #bb8b06;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

hr {
  margin: 10px;
  opacity: 20%;
}



@media (max-width: 720px) {

  .content-premium {
    display: block;

  }

}