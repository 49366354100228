@font-face {
    font-family: Akira;
    src: url(../fuentes/WhiteOnBlack.ttf);
}
.contents {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 300px;
    padding-bottom: 200px;
}

#h1 {
    font-family: Akira;
    font-size: 100px;
}