@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    color: #fff;
}

body {
    background-color: #080202;
    font-family: "Poppins", sans-serif;
    color: #fff;
}


.title-main {
    display: flex;
    height: 200px;
    padding-top: 200px;
    justify-content: center;
    align-items: center;
}

.title-main .title {
    text-align: center;
}


.team {
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 50px;
    margin-bottom: 50px;
    margin-top: 100px;
    margin-bottom: 100px;
    
   
}


.team .caja {
    margin: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0px;
    background-color: rgb(19, 19, 19);
    border-radius: 10px;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;



}


.caja img {
    transform: translateY(-20px);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 4px solid rgb(31, 31, 31);
   

}


#icono {
    background-color: rgb(7, 7, 7);
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    margin-left: 2px;
    margin-right: 2px;
}

@media only screen and (max-width: 600px),
(max-width: 700px),
(max-width: 800px),
(max-width: 900px) {

    .fondo {
        display: block;
    }

    .caja img {
        transform: translateY(0);
        height: 100px;
        width: 100px;
        border-radius: 50%;
    }

    .fondo .caja {
        margin: 20px;

    }

    .team {
        display: block;
    }


}

@media only screen and (max-width: 600px) {

    .fondo .caja {
        margin: 20px;
        margin-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;

    }
    .team .caja {

        width: 350px;
        margin-top: 100px;

    
    
    
    }

    .caja img {
       transform: translateY(-1rem);


    }
}

