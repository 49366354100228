.Partner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.Partner .card {
  display: block;
}
.cnt {
  margin-top: 20px;
  
}

.send {
  text-align: center;
  border-radius: 20px;
  background-color: rgb(42, 143, 15);
}
.title-main {
  display: flex;
  height: 200px;
  padding-top: 200px;
  justify-content: center;
  align-items: center;
}

.title-main .title {
  text-align: center;
}

textarea {
  background-color: rgb(29, 29, 29);
  resize: none;
  border-radius: 10px;
  padding: 15px;
  border: 0;
}

