@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: #fff;
}

body {
  background-color: #080202;
  font-family: "Poppins", sans-serif;
  color: #fff;
}



.terms {
  display: flex;
  height: auto;
  padding-top: 150px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.parrafo span{
  font-size: 25px;
  font-weight: 600;
}

.parrafo{
  opacity: 1;
  padding: 10px;
  text-align: start;
  width: 60%;
}

.terms-p{
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  margin-top: 30px;
  zoom: 150%;
}


@media (max-width: 720px) {

  .terms {
    padding-top: 200px;
  }

  .video {
    margin-top: 30px;
    zoom: 80%;
  }
  
}