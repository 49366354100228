@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: #fff;
}

body {
  background-color: #080202;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}


.info {
  display: flex;
  height: 750px;
  justify-content: center;
  align-items: center;
}

.info .title {
    text-align: center;
    padding-top: 100px;
}

